
/* Product.css */
.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
  background-color: #fff;
}

.product-card .product-image {
  width: 100%; /* Make the image fill the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px 8px 8px 8px; 
}

.product-card h2 {
  margin-top: 0;
  color: #333;
}

.product-card p {
  color: #666;
}

.product-card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.product-card button:hover {
  background-color: #0056b3;
}

.product-image {
  width: 200px; /* Fixed width, adjust as needed */
  height: 200px; /* Fixed height, adjust as needed */
  object-fit: cover; /* This will ensure the image covers the area nicely */
}

@media screen and (max-width: 600px) {
  .product-image {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
}

