
/* CheckoutPage.css */

.checkout-container {
    margin: 0 auto;
    max-width: 900px;
    padding: 20px;
}

.checkout-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.column {
    flex: 1;
}

.column h2 {
    margin-bottom: 10px;
}

.column form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.column form input, .column form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.checkout-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-container button:hover {
    background-color: #45a049;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-row select, .form-row input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
}

.form-row input:last-child {
    margin-right: 0; /* Removes margin from the last input in a row */
}

.manual-entry-link {
    background: none;
    color: blue;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.manual-entry-form {
    max-width: 400px; /* Adjust based on your design */
    margin-left: auto;
    margin-right: auto;
}

.form-field {
    margin-bottom: 10px; /* Space between fields */
}

.form-field input, .form-field button {
    width: 100%; /* Make input and button fill their container */
    padding: 8px; /* Padding for aesthetics */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.form-field button {
    cursor: pointer; /* Change cursor to pointer when hovering over the button */
    background-color: #007bff; /* Example button color */
    color: white; /* Text color for the button */
    border: none; /* Remove border */
}

.form-field button:disabled {
    background-color: #ccc; /* Disabled button color */
}

pre {
    white-space: pre-wrap; /* Ensure the address wraps and respects spacing */
    word-wrap: break-word; /* Break the word to prevent overflow */
    background-color: transparent; /* Optional: Make background transparent */
    border: none; /* Optional: Remove border */
    padding: 0; /* Optional: Remove padding */
    font-family: inherit; /* Match font with the rest of the form */
}
