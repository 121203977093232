
header {
    background-color: #87CEEB;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
header .logo {
    font-size: 24px;
    font-weight: bold;
}
  
header nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}
  
header nav ul li a {
    text-decoration: none;
    color: #333;
}
  