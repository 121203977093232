/* PaymentPage.css */
.payment-page-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
}

.billing-address, .payment-form {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.billing-address h2, .payment-form h2 {
    margin-bottom: 15px;
}

.payment-form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.payment-form h2 {
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}

.payment-form h3 {
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}


.required-field::after {
    content: "*";
    color: red;
    margin-left: 4px;
}

.input-field {
    margin-bottom: 20px;
}

.input-field label {
    display: block;
    margin-bottom: 8px;
}

.input-field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}


.payment-form p {
    margin-top: 0;
    margin-bottom: 20px;
}

.payment-form button {
    width: 100%;
    padding: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

.payment-form button:hover {
    background-color: #0056b3;
}

.payment-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.address-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.change-button {
    margin-left: auto; /* Pushes the button to the right */
    padding: 8px 16px; /* Example padding, adjust as needed */
    background-color: #007bff; /* Example background color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable button */
}

.change-button:hover {
    background-color: #0056b3; /* Darker shade on hover, example */
}

.payment-instructions {
    margin-bottom: 20px;
}

.expiry-cvv-field {
    display: flex;
    justify-content: space-between;
}

.half-width {
    width: 48%; /* Adjust the width so they fit side by side */
}
