
/* Navbar.css */
.navbar {
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar-logo a {
    text-decoration: none;
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
  }
  
  .navbar-links li {
    margin-left: 20px;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: #333;
  }
  
  nav ul {
    display: flex;
    list-style-type: none;
    /* ... other styles ... */
}

.shopping-cart-icon {
    margin-left: auto; /* This pushes the icon to the right */
    position: relative;
    display: inline-block; /* Ensure this is inline-block or block */
    align-items: center;
    font-size: 1.5rem; /* Adjust the size as needed */
    cursor: pointer;
}

.cart-item-count {
  background-color: green;
  color: white;
  border-radius: 50%; /* Make it round */
  padding: 0; /* Remove padding */
  font-size: 0.7em; /* Adjust font size as needed */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  height: 20px; /* Fixed height */
  width: 20px; /* Fixed width, same as height to make it round */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Ensure text is centered */
}

